<template>
  <div id="app" class="container" style="background-color: #f7fafc">
    <h1 class="text-center"></h1>
    <div class="row">
      <div class="col">
        <projects-table title="Light Table"></projects-table>
      </div>
    </div>

    <div class="container-fluid mt-6" style="background-color: white">
      <h1>sound test</h1>
      <mini-audio audio-source="http://localhost:8000/images/Vocabulary_Categories/vocab_months/Haziran.m4a">
      </mini-audio>
      <mini-audio audio-source="http://localhost:8000/images/Vocabulary_Categories/vocab_months/Haziran.m4a">
      </mini-audio>
    </div>
  </div>
</template>                           

<script>
import axios from "axios";
export default {
  components: {},
  data() {
    return {
      words: [],



    };
  },

  mounted() {
    this.audio.loop = false;

    axios.get(this.$appName + "/api/word/show").then((res) => {
      this.words = res.data;

      // this.musicPlaylist1 = this.words.map((item) => {
      //   return {
      //     id: item.id,
      //     url: this.imgUrl + "/images/" + item.t_sound_file,
      //   };
      // });

      this.wordsLenght = res.data.length;
      this.rows = res.data.length;
    });


  },

  methods: {
    pause() {
      // let audio = this.$refs.audio;
    },

  },
};
</script>
