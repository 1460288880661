<template>
  <div id="app" class="container" style="background-color: #f7fafc">
    <div v-if="toaster">
      <ToasterU v-if="form.id" />
      <ToasterA v-else />
    </div>
    <ToasterAc v-if="toasterac" />
    <ToasterDe v-if="toasterde" />
    <ToasterD v-if="toasterd" />
    <Loader1 v-if="loader" />
    <h1 class="text-center"></h1>
    <div class="row">
      <div class="col">
        <projects-table title="Light Table"></projects-table>
      </div>
    </div>
    <div></div>

    <div class="container-fluid mt-6" style="background-color: white">
      <br />
      <!-- <BackButton /> -->

      <div class="mt-2">
        <!-- <h2 v-if="form.id" style="font-size: 1.5rem">Edit Notification</h2> -->
        <h2 style="font-size: 1.5rem">Add Notification</h2>
      </div>
      <hr />
      <form method="post" @submit.prevent="AddActivity" @keydown="form.onKeydown($event)" enctype="multipart/form-data">
        <input v-model="form.id" type="hidden" name="id" placeholder="Enter Name" />
        <label class="lablestyle mt-2">
          Add message<span class="text-danger"> *</span></label>
        <div class="form-outline">
          <textarea v-model="form.message" class="form-control notificationMessage" id="notificationMessage" rows="8"
            :maxlength="max1" style="height: 100px !important"></textarea>

          <div class="row">
            <div class="col-8">
              <span class="text-danger" style="font-size: 13px" v-if="errors.message">{{ errors.message[0] }}</span>
            </div>
            <div class="col-4">
              <div class="showLength">
                <span>Characters </span>
                <span v-text="max1 - form.message.length"> </span>
              </div>
            </div>
          </div>
        </div>

        <label class="lablestyle " for="">Select Option <span class="text-danger"> *</span></label>
        <div class="row">
          <div class="col-2">
            <input type="radio" @change="picked($event)" v-model="form.type" checked="true" class="mr-1" id="css"
              name="type" value="generic" />
            <label for="css">Generic</label>
          </div>
          <div class="col-6">
            <input value="content" @change="picked($event)" type="radio" v-model="form.type" class="mr-1"
              id="javascript" name="type" />
            <label for="javascript">Content Update</label>
          </div>
        </div>

        <br />

        <div class="text-right">
          <button type="submit" style="background: #1f7dad; border-radius: 26px" class="mb-5 btn btn-primary m-0"
            :disabled="form.busy">
            Submit
          </button>
        </div>
      </form>
    </div>
    <br />
    <br />
  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css">

</style>
<script>
import Form from "vform";
import axios from "axios";
import Loader1 from "../Loader1";
import ToasterA from "../ToasterA";
import ToasterU from "../ToasterU";
import Swal from "sweetalert2";
import ToasterAc from "../ToasterAc";
import ToasterDe from "../ToasterDe";
import ToasterD from "../ToasterD";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import BackButton from "../ButtonComponent/BackButton.vue";

export default {
  components: {
    Loader1,
    ToasterA,
    ToasterU,
    ToasterDe,
    ToasterAc,
    ToasterD,
    // BackButton,
  },

  data() {
    return {
      errors: [],
      words: [],
      toasterac: false,
      toasterde: false,
      toasterd: false,
      loader: false,
      max1: 500,
      imgUrl: this.$appName,
      toaster: false,
      img_folder: "/images/",
      base_url: this.$appName,
      editor: ClassicEditor,
      editorConfig: {},

      form: new Form({
        id: "",
        message: "",
        type: "generic",
        status: "all",
      }),
    };
  },

  created() {
    // axios
    //   .get(
    //     this.$appName +
    //       `/api/notification/Notification/${this.$route.params.id}`
    //   )
    //   .then((res) => {
    //     if (res.data.id != undefined) {
    //       this.form = res.data;
    //     }
    //   });
  },

  mounted() { },

  methods: {
    picked(event) {
      this.form.type = event.target.value;
    },
    async AddActivity() {
      this.loader = true;
      let data = new FormData();
      data.append("id", this.form.id);
      data.append("message", this.form.message);
      data.append("status", this.form.status);
      data.append("type", this.form.type);

      let addNewActivity = await axios
        .post(this.$appName + "/api/notification/store", data)
        .catch((error) => {
          this.loader = false;
          this.errors = error.response.data.errors;
        });
      if (addNewActivity) {
        console.log('addNewActivity', addNewActivity);
        this.toaster = true;
        this.toasterprop = true;
        this.loader = false;
        setTimeout(() => {
          location.reload();
        }, 0);
      }
    },
  },
};
</script>

<style>

</style>
